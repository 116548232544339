<template>
  <div class="container mt-3">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-center px-3">
        <div class="col-md-6"><h2>Tetapan Pengguna</h2></div>
      </div>
      <div class="container-box mt-3 bg-white">
        <div class="contents mt-3">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: currentActiveNav == 1 }"
                @click="
                  currentActiveNav = 1;
                  currentPage = 0;
                  getBh(0);
                "
                >Bakal Haji</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{
                  active: currentActiveNav == 3 || currentActiveNav == 2,
                }"
                @click="
                  currentActiveNav = 3;
                  currentPage = 0;
                  getCurrentYearPekta(0);
                "
                >PEKTA</a
              >
            </li>
            <!-- <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: currentActiveNav == 2 }"
                @click="currentActiveNav = 2; currentPage = 0; getApprovedPekta(0)"
                >PEKTA Berdaftar</a
              >
            </li> -->
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: currentActiveNav == 4 }"
                @click="
                  currentActiveNav = 4;
                  currentPage = 0;
                  getKatam(0);
                "
                >Permohonan KATAM</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: currentActiveNav == 5 }"
                @click="
                  currentActiveNav = 5;
                  currentPage = 0;
                  getPihtas(0);
                "
                >Permohonan PIHTAS</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: currentActiveNav == 6 }"
                @click="
                  currentActiveNav = 6;
                  getPic();
                "
                >Orang Yang Bertanggungjawab</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container bg-white">
      <div class="row mx-0">
        <div v-if="isLoading" class="col-md-12">
          <div class="text-center p-5">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else class="col-md-12">
          <div class="container-box mt-2" v-if="currentActiveNav == 1">
            <div class="row align-items-center">
              <div class="col-md-4">
                <div class="d-flex justify-content-start">
                  <h5 class="me-2 mt-2">Senarai Pengguna</h5>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex justify-content-end">
                  <div class="btn-group">
                    <button
                      class="btn btn-outline-primary ms-2 me-2"
                      v-if="currentActiveNav == 1"
                      @click="handleDownload('senarai_bh')"
                      :disabled="isDownloading"
                    >
                      <span v-if="isDownloading">
                        <i class="fas fa-spinner fa-spin"></i> Sila Tunggu...
                      </span>
                      <span v-else> Muat turun </span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      v-if="currentPage != 0"
                      @click="getBh(-1)"
                    >
                      <i class="bi bi-chevron-left"></i>
                      Sebelum
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-primary ms-2 me-2"
                    >
                      Page {{ currentPage + 1 }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      v-if="!(users.length < 100) && users.length !== 0"
                      @click="getBh(1)"
                    >
                      Seterusnya
                      <i class="bi bi-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <table class="table table-hover table-borderless mt-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">No. Pendaftaran</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Emel</th>
                  <th scope="col">No. Telefon</th>
                </tr>
              </thead>
              <tbody v-if="users && users.length">
                <tr v-for="(user, i) in users" :key="user.id">
                  <td scope="row">{{ i + 1 + currentPage * pageSize }}</td>
                  <td scope="row">{{ user.registration_number }}</td>
                  <td>
                    {{ user.full_name }}
                  </td>
                  <td>
                    {{ user.email }}
                  </td>
                  <td>
                    <!-- {{
                      user.phone_mobile
                        ? user.phone_mobile
                            .toString()
                            .replace(/[^0-9+]/g, "")
                            .replace(/^(00)?(60)?(1)/, "+60$3")
                        : "-"
                    }} -->
                    {{
                      user.phone_mobile
                        ? user.phone_mobile
                            .toString()
                            .replace(/[^0-9+]/g, "")
                            .replace(/^\+6/, '')
                        : "-"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="container-box mt-2"
            v-if="currentActiveNav != 1 && currentActiveNav != 6"
          >
            <div class="row align-items-center mt-2">
              <div class="col-md-4">
                <div class="d-flex justify-content-start">
                  <h5 class="me-2 mt-2">Senarai Pengguna</h5>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex justify-content-end flex-column flex-md-row">
                  <div class="btn-group">
                    <div class="btn-group btn-coupled me-2" role="group">
                      <button
                        type="button"
                        class="btn btn-left"
                        :class="{ active: pektaType == 'baru' }"
                        @click="pektaType = 'baru'"
                      >
                        Baru
                      </button>
                      <button
                        type="button"
                        class="btn btn-right"
                        :class="{ active: pektaType == 'berdaftar' }"
                        @click="pektaType = 'berdaftar'"
                      >
                        Berdaftar
                      </button>
                    </div>
                    <button
                      class="btn btn-outline-primary ms-2 me-2"
                      v-if="currentActiveNav == 3"
                      @click="handleDownload('permohonan_pekta')"
                      :disabled="isDownloading"
                    >
                      <span v-if="isDownloading">
                        <i class="fas fa-spinner fa-spin"></i> Sila Tunggu...
                      </span>
                      <span v-else> Muat turun </span>
                    </button>
                    <button
                      class="btn btn-outline-primary ms-2 me-2"
                      v-if="currentActiveNav == 4"
                      @click="handleDownload('permohonan_katam')"
                      :disabled="isDownloading"
                    >
                      <span v-if="isDownloading">
                        <i class="fas fa-spinner fa-spin"></i> Sila Tunggu...
                      </span>
                      <span v-else> Muat turun </span>
                    </button>
                    <button
                      class="btn btn-outline-primary ms-2 me-2"
                      v-if="currentActiveNav == 5"
                      @click="handleDownload('permohonan_pihtas')"
                      :disabled="isDownloading"
                    >
                      <span v-if="isDownloading">
                        <i class="fas fa-spinner fa-spin"></i> Sila Tunggu...
                      </span>
                      <span v-else> Muat turun </span>
                    </button>
                    <div class="dropdown me-2">
                      <button
                        class="btn btn-outline-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuFilter"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        Saring
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuFilter"
                      >
                        <li>
                          <a
                            class="dropdown-item text-reset"
                            data-bs-toggle="collapse"
                            href="#collapseStateFilter"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseStateFilter"
                            >Negeri</a
                          >
                          <div class="collapse" id="collapseStateFilter">
                            <div class="p-2 mb-2">
                              <select
                                class="form-select"
                                v-model="filterOption.state"
                              >
                                <option :value="null" disabled selected hidden>
                                  Pilih Negeri
                                </option>
                                <option
                                  v-for="state in states"
                                  :key="state"
                                  :value="state"
                                >
                                  {{ state }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-reset"
                            data-bs-toggle="collapse"
                            href="#collapseGenderFilter"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseGenderFilter"
                            >Jantina</a
                          >
                          <div class="collapse" id="collapseGenderFilter">
                            <div class="p-2 mb-2">
                              <select
                                class="form-select"
                                v-model="filterOption.gender"
                              >
                                <option :value="null" disabled selected hidden>
                                  Pilih Jantina
                                </option>
                                <option value="lelaki">Lelaki</option>
                                <option value="perempuan">Perempuan</option>
                              </select>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-reset"
                            data-bs-toggle="collapse"
                            href="#collapseStatusFilter"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseStatusFilter"
                            >Status</a
                          >
                          <div class="collapse" id="collapseStatusFilter">
                            <div class="p-2 mb-2">
                              <select
                                class="form-select"
                                v-model="filterOption.status"
                              >
                                <option :value="null" disabled selected hidden>
                                  Pilih Status
                                </option>
                                <option value="pending">
                                  Menunggu Tindakan
                                </option>
                                <option value="processing">Dalam Proses</option>
                                <option value="interview">
                                  Panggilan Temuduga
                                </option>
                                <option value="approved">Diluluskan</option>
                                <option value="rejected">Ditolak</option>
                              </select>
                            </div>
                          </div>
                        </li>
                        <!-- <li>
                          <a
                            class="dropdown-item text-reset"
                            data-bs-toggle="collapse"
                            href="#collapseAgeFilter"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseAgeFilter"
                            >Umur</a
                          >
                          <div class="collapse" id="collapseAgeFilter">
                            <div class="d-flex p-2">
                              <input
                                class="form-control me-2 input-custom"
                                placeholder="Min umur"
                                type="number"
                                v-model="filterOption.age.min"
                              />
                              <input
                                class="form-control me-2 input-custom"
                                placeholder="Max umur"
                                type="number"
                                v-model="filterOption.age.max"
                              />
                            </div>
                          </div>
                        </li> -->
                        <li>
                          <a
                            class="dropdown-item text-reset"
                            data-bs-toggle="collapse"
                            href="#collapseYearFilter"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseYearFilter"
                            >Tahun Lantikan</a
                          >
                          <div class="collapse" id="collapseYearFilter">
                            <select
                              class="form-select"
                              v-model="filterOption.year"
                            >
                              <option :value="null" disabled selected hidden>
                                Pilih Tahun
                              </option>
                              <option
                                v-for="year in generateYears(1990, 2050)"
                                :key="year"
                                :value="year"
                              >
                                {{ year }}
                              </option>
                            </select>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <button
                      v-if="
                        filterOption.state ||
                        filterOption.gender ||
                        filterOption.age.min ||
                        filterOption.age.max ||
                        filterOption.year ||
                        filterOption.status
                      "
                      class="btn btn-sm border"
                      type="button"
                      @click="
                        () => {
                          filterOption.state = null;
                          filterOption.gender = null;
                          filterOption.age.min = null;
                          filterOption.age.max = null;
                          filterOption.year = null;
                          filterOption.status = null;
                        }
                      "
                    >
                      Set semula
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      v-if="currentPage != 0"
                      @click="getPektaUser(-1)"
                    >
                      <i class="bi bi-chevron-left"></i>
                      Sebelum
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-primary ms-2 me-2"
                    >
                      Page {{ currentPage + 1 }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      v-if="!(users.length < 100) && users.length !== 0"
                      @click="getPektaUser(1)"
                    >
                      Seterusnya
                      <i class="bi bi-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <table class="table table-hover table-borderless mt-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Tahun Lantikan</th>
                  <th scope="col">Tahun Daftar</th>
                  <!-- <th scope="col">Emel</th> -->
                  <th scope="col">Negeri</th>
                  <th scope="col">Daerah</th>
                  <th scope="col" v-if="currentActiveNav != 2">Status</th>
                  <th scope="col" v-if="currentActiveNav != 2">Tindakan</th>
                </tr>
              </thead>
              <tbody v-if="users && users.length">
                <tr v-for="(user, i) in findNameUsers()" :key="user.id">
                  <td scope="row">{{ i + 1 + currentPage * pageSize }}</td>
                  <td>
                    {{ user.full_name }}
                  </td>
                  <td scope="row">
                    {{
                      user.registration_number
                        ? user.registration_number.substring(0, 4)
                        : "-"
                    }}
                  </td>
                  <!-- <td>{{  getYearOnly(user.createdAt) }}</td> -->
                  <td>{{
                    user.pekta && user.pekta.katam_applications && user.pekta.katam_applications.length > 0
                      ? getYearOnly(user.pekta.katam_applications[0].createdAt)
                      : '' }}</td>
                  <td>{{ user.state ? user.state.toUpperCase() : '' }}</td>
                  <td>{{ user.district ? user.district.toUpperCase() : '' }}</td>
                  <!--<td>
                  {{
                      user.phone_mobile
                      ? user.phone_mobile.toString().replace(/[^0-9+]/g, '').replace(/^(00)?(60)?(1)/, '+60$3')
                      : "-"
                  }}
                  </td> -->
                  <td>
                    <span class="badge bg-danger" style="font-size: 0.9rem">{{
                      getStatus(user)
                    }}</span>
                  </td>
                  <td v-if="currentActiveNav == 3">
                    <button
                      class="btn btn-outline-secondary mx-1"
                      data-bs-toggle="modal"
                      data-bs-target="#pektaStatusChange"
                      async
                      @click="
                        activeModalNav = 1;
                        openChangeModal(user, 'pekta');
                      "
                    >
                      Tukar&nbsp;
                    </button>
                  </td>
                  <td v-if="currentActiveNav == 4">
                    <button
                      class="btn btn-outline-secondary mx-1"
                      data-bs-toggle="modal"
                      data-bs-target="#katamStatusChange"
                      async
                      @click="
                        activeModalNav = 1;
                        openChangeModal(user, 'katam');
                      "
                    >
                      Tukar&nbsp;
                    </button>
                  </td>
                  <td v-if="currentActiveNav == 5">
                    <button
                      class="btn btn-outline-secondary mx-1"
                      data-bs-toggle="modal"
                      data-bs-target="#pihtasStatusChange"
                      async
                      @click="
                        activeModalNav = 1;
                        openChangeModal(user, 'pihtas');
                      "
                    >
                      Tukar&nbsp;
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="container-box mt-4" v-if="currentActiveNav == 6">
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="d-flex justify-content-start">
                  <h5 class="me-2 mt-2">Tetapan Orang Bertanggungjawab</h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-success" @click="updatePersonInCharge">
                    Ubah Tetapan
                  </button>
                </div>
              </div>
              <div class="row justify-content-center mb-2">
                <div class="col-md-6">
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1">Nama</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="picForm.name"
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1">Email</label>
                    <input
                      class="form-control"
                      type="email"
                      v-model="picForm.email"
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1">Phone</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="picForm.phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      id="pektaStatusChange"
      tabindex="-1"
      aria-labelledby="pektaStatusChangeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container-box bg-white">
              <h3>Maklumat Pengguna</h3>
              <div class="contents mt-3 d-inline-flex justify-content-center">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :class="{ active: activeModalNav == 1 }"
                      @click="activeModalNav = 1"
                      >Maklumat Diri</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :class="{ active: activeModalNav == 2 }"
                      @click="activeModalNav = 2"
                      >Pekerjaan, Kursus & Dokumen</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-md-12">
                <div class="container-box mt-2" v-if="activeModalNav == 1">
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1">Nama</label>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="Tiada data"
                      v-model="pektaApplication.fullname"
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >No. Kad Pengenalan</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="Tiada data"
                      v-model="pektaApplication.nric"
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Status Perkahwinan</label
                    >
                    <select
                      disabled
                      class="form-select"
                      v-model="pektaApplication.marital_status"
                    >
                      <option
                        v-for="item in maritalstatuses"
                        :key="item.display"
                        :value="item.value"
                      >
                        {{ item.display }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >No. Telefon</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="Tiada data"
                      v-model="pektaApplication.phone_number"
                    />
                  </div>
                  <div
                    class="form-group mt-3"
                    v-if="pektaApplication.do_smoking"
                  >
                    <label for="name" class="control-label mb-1"
                      >Status Perokok</label
                    >
                    &nbsp;&nbsp;&nbsp;
                    <input
                      disabled
                      class="form-check-input"
                      type="checkbox"
                      v-model="pektaApplication.do_smoking"
                    />
                    <label class="form-check-label ms-3" for="kuiz">
                      {{
                        pektaApplication.do_smoking
                          ? "Merokok"
                          : "Tidak merokok"
                      }}
                    </label>
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Nama Majikan</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="Tiada data"
                      v-model="pektaApplication.employer_name"
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Tarikh Dihantar</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="Tiada data"
                      v-model="pektaApplication.submitted_date"
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Tarikh Mula Tauliah Negeri</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="Tiada data"
                      v-model="pektaApplication.qualification_start_date"
                    />

                    <label for="name" class="control-label mb-1"
                      >Tarikh Tamat Tauliah Negeri</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="Tiada data"
                      v-model="pektaApplication.qualification_end_date"
                    />
                  </div>
                  <div
                    class="form-group mt-3"
                    v-if="pektaApplication.pekta_application_assessments"
                  >
                    <label for="name" class="control-label mb-1"
                      >Keputusan Ujian PEKTA</label
                    >
                    <p>
                      {{
                        pektaApplication.pekta_application_assessments
                          .question_correct_count
                      }}
                      /
                      {{
                        pektaApplication.pekta_application_assessments
                          .question_count
                      }}
                      &nbsp;&nbsp;&nbsp; ({{
                        pektaApplication.pekta_application_assessments.is_pass
                          ? "LULUS"
                          : "GAGAL"



                      }})
                    </p>
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >No. Pendaftaran PEKTA</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Masukkan no. pendaftaran PEKTA"
                      v-model="pektaApplication.registration_number"
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Status Permohonan</label
                    >
                    <select
                      class="form-select"
                      v-model="pektaApplication.status"
                    >
                      <option
                        v-for="(status, i) in pektaStatusList"
                        :key="status.name + i"
                        :value="status.value"
                      >
                        {{ status.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="container-box mt-2" v-if="activeModalNav == 2">
                  <div class="row mx-0">
                    <div class="col-12 px-0">
                      <h5 class="fw-bold">Akademik & Sijil</h5>
                      <ul class="list-group list-group-flush">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center custom-list px-0 mb-2"
                          v-for="(
                            qualification, i
                          ) in pektaApplication.pekta_qualification"
                          :key="'q' + i"
                          v-if="
                            !qualification.type.includes(
                              'surat tawaran haji'
                            ) && !qualification.type.includes('KUTAHU') && qualification.type
                          "
                        >
                          <div style="float: left; width: 80%">
                            <p>
                              {{ qualification.type }}
                              {{                                   qualification.end_date
                                    ? `(${getYear(qualification.end_date)})`
                                    : "" }}
                            </p>
                            <span class="inbox-date">{{
                              qualification.level
                            }}</span>
                          </div>
                          <div
                            class="float-end me-2"
                            v-if="qualification.cert_file"
                          >
                            <a
                              href="#"
                              class="me-3"
                              data-bs-toggle="modal"
                              data-bs-target="#imageModal"
                              @click="
                                selectedImageViewer.name = qualification.type;
                                selectedImageViewer.link = qualification.cert_file.url;
                              "
                            >
                              <i class="bi bi-eye"></i>
                            </a>
                            <a
                              :href="`${addHttps(qualification.cert_file.url)}`"
                              target="_blank"
                            >
                              <i class="bi bi-download"></i>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 mb-4 row gx-1">
            <div class="col-6">
              <button
                type="button"
                style="width: 100%"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Batal
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                style="width: 100%"
                class="col-4 btn btn-primary"
                @click="updatePektaApplication()"
              >
                Ubah Aplikasi&nbsp;<i class="bi bi-check-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      id="katamStatusChange"
      tabindex="-1"
      aria-labelledby="katamStatusChangeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h5>Maklumat Permohonan</h5>
            <div class="row mx-0">
              <div class="col-md-12">
                <h5 class="fw-bold">Fail Berkenaan</h5>
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center custom-list px-0 mb-2"
                    v-if="katamApplication.file_kutahu_cert"
                  >
                    <div style="float: left; width: 80%">
                      <p class="mb-0">Sijil Pembimbing Ibadat Haji</p>
                    </div>
                    <div class="float-end me-2">
                      <a
                        href="#"
                        class="me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#imageModal"
                        @click="
                                selectedImageViewer.name = katamApplication.file_mhpk_cert.type;
                                selectedImageViewer.link = katamApplication.file_mhpk_cert.url;
                              "
                      >
                        <i class="bi bi-eye"></i>
                      </a>
                      <a
                        :href="`${addHttps(katamApplication.file_kutahu_cert.url)}`"
                        target="_blank"
                      >
                        <i class="bi bi-download"></i>
                      </a>
                    </div>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center custom-list px-0 mb-2"
                    v-if="katamApplication.file_mhpk_cert"
                  >
                    <div style="float: left; width: 80%">
                      <p class="mb-0">Rekod Muzakarah Haji</p>
                    </div>
                    <div class="float-end me-2">
                      <a
                        href="#"
                        class="me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#imageModal"
                        @click="
                                selectedImageViewer.name = katamApplication.file_mhpk_cert.type;
                                selectedImageViewer.link = katamApplication.file_mhpk_cert.url;
                              "
                      >
                        <i class="bi bi-eye"></i>
                      </a>
                      <a
                        :href="`${addHttps(katamApplication.file_mhpk_cert.url)}`"
                        target="_blank"
                      >
                        <i class="bi bi-download"></i>
                      </a>
                    </div>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center custom-list px-0 mb-2"
                    v-if="katamApplication.file_tafaqquh_haji_cert"
                  >
                    <div style="float: left; width: 80%">
                      <p class="mb-0">Tauliah Mengajar Agama Islam Negeri</p>
                    </div>
                    <div class="float-end me-2">
                      <a
                        href="#"
                        class="me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#imageModal"
                        @click="
                                selectedImageViewer.name = katamApplication.file_tafaqquh_haji_cert.type;
                                selectedImageViewer.link = katamApplication.file_tafaqquh_haji_cert.url;
                              "
                      >
                        <i class="bi bi-eye"></i>
                      </a>
                      <a
                        :href="`${addHttps(katamApplication.file_tafaqquh_haji_cert.url)}`"
                        target="_blank"
                      >
                        <i class="bi bi-download"></i>
                      </a>
                    </div>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center custom-list px-0 mb-2"
                    v-if="katamApplication.file_tauliah_cert"
                  >
                    <div style="float: left; width: 80%">
                      <p class="mb-0">Sijil Pengtauliahan</p>
                    </div>
                    <div class="float-end me-2">
                      <a
                        :href="`${addHttps(katamApplication.file_tauliah_cert.url)}`"
                        target="_blank"
                      >
                        <i class="bi bi-download"></i>
                      </a>
                    </div>
                  </li>
                </ul>
                <label for="name" class="control-label mb-1"
                  >Tarikh Mula Tauliah Negeri</label
                >
                <input
                  type="text"
                  disabled
                  class="form-control"
                  placeholder="Tiada data"
                  v-model="katamApplication.qualification_start_date"
                />

                <label for="name" class="control-label mb-1"
                  >Tarikh Tamat Tauliah Negeri</label
                >
                <input
                  type="text"
                  disabled
                  class="form-control"
                  placeholder="Tiada data"
                  v-model="katamApplication.qualification_end_date"
                />
                <div
                  class="form-group mt-3"
                  v-if="katamApplication.pekta_application_assessments"
                >
                  <label for="name" class="control-label mb-1"
                    >Keputusan Ujian KATAM</label
                  >
                  <p>
                    {{
                        katamApplication.pekta_application_assessments
                          .question_correct_count
                    }}
                    /
                    {{
                        katamApplication.pekta_application_assessments
                          .question_count
                    }}
                    &nbsp;&nbsp;&nbsp; ({{
                        katamApplication.pekta_application_assessments.is_pass
                          ? "LULUS"
                          : "GAGAL"



                    }})
                  </p>
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Status Permohonan</label
                  >
                  <select class="form-select" v-model="katamApplication.status">
                    <option
                      v-for="(status, i) in pektaStatusList"
                      :key="status.name + i"
                      :value="status.value"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 mb-4 row gx-1">
            <div class="col-6">
              <button
                type="button"
                style="width: 100%"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Batal
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                style="width: 100%"
                class="col-4 btn btn-primary"
                @click="updateKatamApplication()"
              >
                Ubah Permohonan&nbsp;<i class="bi bi-check-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      id="pihtasStatusChange"
      tabindex="-1"
      aria-labelledby="pihtasStatusChangeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h5>Maklumat Permohonan</h5>
            <div class="row mx-0">
              <div class="col-md-12">
                <h5 class="fw-bold">Fail Berkenaan</h5>
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center custom-list px-0 mb-2"
                    v-if="pihtasApplication.file_sijil_pembimbing_ibadat"
                  >
                    <div style="float: left; width: 80%">
                      <p class="mb-0">Sijil Pembimbing Ibadat Haji</p>
                    </div>
                    <div class="float-end me-2">
                      <a
                        href="#"
                        class="me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#imageModal"
                        @click="
                            selectedImageViewer.link = pihtasApplication.file_sijil_pembimbing_ibadat.url;
                          "
                      >
                        <i class="bi bi-eye"></i>
                      </a>
                      <a
                        :href="`${addHttps(pihtasApplication.file_sijil_pembimbing_ibadat.url)}`"
                        target="_blank"
                      >
                        <i class="bi bi-download"></i>
                      </a>
                    </div>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center custom-list px-0 mb-2"
                    v-if="pihtasApplication.file_izin_suami_wali"
                  >
                    <div style="float: left; width: 80%">
                      <p class="mb-0">Fail Izin Suami Wali</p>
                    </div>
                    <div class="float-end me-2">
                      <a
                        href="#"
                        class="me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#imageModal"
                        @click="
                                selectedImageViewer.link = pihtasApplication.file_izin_suami_wali[0].url;
                              "
                      >
                        <i class="bi bi-eye"></i>
                      </a>
                      <a
                        :href="`${addHttps(pihtasApplication.file_izin_suami_wali[0].url)}`"
                        target="_blank"
                      >
                        <i class="bi bi-download"></i>
                      </a>
                    </div>
                  </li>
                </ul>
                <div
                  class="form-group mt-3"
                  v-if="pihtasApplication.sijil_pembimbing_ibadat_year"
                >
                  <label for="name" class="control-label mb-1"
                    >Tahun Sijil Pembimbing Ibadat</label
                  >
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    placeholder="Tiada data"
                    v-model="pihtasApplication.sijil_pembimbing_ibadat_year"
                  />
                </div>
                <div
                  class="form-group mt-3"
                  v-if="pihtasApplication.pekta_application_assessments"
                >
                  <label for="name" class="control-label mb-1"
                    >Keputusan Ujian PIHTAS</label
                  >
                  <p>
                    {{
                        pihtasApplication.pekta_application_assessments
                          .question_correct_count
                    }}
                    /
                    {{
                        pihtasApplication.pekta_application_assessments
                          .question_count
                    }}
                    &nbsp;&nbsp;&nbsp; ({{
                        pihtasApplication.pekta_application_assessments.is_pass
                          ? "LULUS"
                          : "GAGAL"



                    }})
                  </p>
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Status Permohonan</label
                  >
                  <select
                    class="form-select"
                    v-model="pihtasApplication.status"
                  >
                    <option
                      v-for="(status, i) in pektaStatusList"
                      :key="status.name + i"
                      :value="status.value"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 mb-4 row gx-1">
            <div class="col-6">
              <button
                type="button"
                style="width: 100%"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Batal
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                style="width: 100%"
                class="col-4 btn btn-primary"
                @click="updatePihtasApplication()"
              >
                Ubah Permohonan&nbsp;<i class="bi bi-check-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      id="imageModal"
      tabindex="-1"
      aria-labelledby="imageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h3>{{ selectedImageViewer.name }}</h3>
            <button
              v-if="currentActiveNav == 3"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#pektaStatusChange"
            >
              <h2 aria-hidden="true">&times;</h2>
            </button>

            <button
              v-if="currentActiveNav == 4"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#katamStatusChange"
            >
              <h2 aria-hidden="true">&times;</h2>
            </button>

            <button
              v-if="currentActiveNav == 5"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#pihtasStatusChange"
            >
              <h2 aria-hidden="true">&times;</h2>
            </button>
          </div>
          <div class="modal-body">
            <div class="row p-4">
              <div class="col-md-12">
                <div style="border: 4px solid #666">
                  <template v-if="isImage(selectedImageViewer.link)">
                    <img :src="selectedImageViewer.link" alt="" width="100%" />
                  </template>
                  <template v-else>
                    <object
                      :data="selectedImageViewer.link"
                      type="application/pdf"
                      width="100%"
                      height="678"
                    >
                      <iframe
                        :src="selectedImageViewer.link"
                        width="100%"
                        height="678"
                      >
                        <p>This browser does not support PDF!</p>
                      </iframe>
                    </object>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 mb-4 row gx-1">
            <div class="col-12">
              <button
                v-if="currentActiveNav == 3"
                type="button"
                style="width: 100%"
                class="btn btn-outline-secondary"
                data-bs-toggle="modal"
                data-bs-target="#pektaStatusChange"
              >
                Batal
              </button>
              <button
                v-if="currentActiveNav == 4"
                type="button"
                style="width: 100%"
                class="btn btn-outline-secondary"
                data-bs-toggle="modal"
                data-bs-target="#katamStatusChange"
              >
                Batal
              </button>
              <button
                v-if="currentActiveNav == 5"
                type="button"
                style="width: 100%"
                class="btn btn-outline-secondary"
                data-bs-toggle="modal"
                data-bs-target="#pihtasStatusChange"
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showProgressModal" class="modal-overlay">
      <div class="modal-download">
        <h3>Sila tunggu, rekod sedang dimuat turun</h3>
        <!-- <p>Rekod dimuat turun: {{ downloadedRecords }} / {{ totalRecords }}</p> -->
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="{ width: progress + '%' }"></div>
        </div>
        <p>{{ progress }}% completed</p>
      </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from "vuex";
import qs from "qs";
import API from "../../utils/API";
import moment from "moment";
import $ from "jquery";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      showProgressModal: false, // Controls modal visibility
    progress: 0, // Progress percentage
    downloadedRecords: 0, // Tracks downloaded records
    totalRecords: 0, // Tracks total records
      registration_number: "",
      isEdit: false,
      maritalstatuses: [
        { value: "single", display: "Bujang" },
        { value: "married", display: "Berkahwin" },
      ],
      roles: [
        { role_name: "Bakal Haji", role_id: 2 },
        { role_name: "Pekta", role_id: 3 },
      ],
      pektaStatusList: [
        { value: "approved", name: "Diluluskan" },
        { value: "processing", name: "Dalam Proses" },
        { value: "draft", name: "Draf" },
        { value: "rejected", name: "Ditolak" },
        { value: "pending", name: "Menunggu Tindakan" },
        { value: "interview", name: "Panggilan Temuduga" },
      ],
      currentPage: 0,
      pageSize: 100,
      pektaUsers: [],
      users: [],
      allPekta: [],
      allBh: [],
      allKatam: [],
      allPihtas: [],
      filteredUsers: [],
      picForm: {
        id: null,
        name: null,
        email: null,
        phone: null,
      },
      pektaApplication: {
        userId: null,
        pektaId: null,
        applicationId: null,
        registration_number: null,
        originalStatus: null,
        status: null,
        fullname: null,
        nric: null,
        submitted_date: null,
        marital_status: null,
        phone_number: null,
        do_smoking: null,
        occupation: null,
        qualification_start_date: null,
        qualification_end_date: null,
        pekta_qualification: [],
        pekta_application_assessments: [],
      },
      katamApplication: {
        userId: null,
        pektaId: null,
        applicationId: null,
        originalStatus: null,
        status: null,
        file_kutahu_cert: null,
        file_mhpk_cert: null,
        file_tafaqquh_haji_cert: null,
        file_tauliah_cert: null,
        qualification_start_date: null,
        qualification_end_date: null,
        pekta_application_assessments: [],
      },
      pihtasApplication: {
        userId: null,
        pektaId: null,
        applicationId: null,
        originalStatus: null,
        status: null,
        file_sijil_pembimbing_ibadat: null,
        file_izin_suami_wali: null,
        sijil_pembimbing_ibadat_year: null,
        pekta_application_assessments: [],
      },
      currentActiveNav: null,
      activeModalNav: null,
      selectedUserId: null,
      isLoading: false,
      isDownloading: false,
      pektaType: "baru", //baru or berdaftar,
      states: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sarawak",
        "Selangor Darul Ehsan",
        "Terengganu",
        "Kuala Lumpur",
        "Labuan",
        "Sabah",
        "Putrajaya",
      ],
      filterOption: {
        state: null,
        gender: null,
        age: {
          min: null,
          max: null,
        },
        year: null,
        status: null,
      },
      selectedImageViewer: {
        name: null,
        link: null,
      },
      pektaSetting: null
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    showProgressModal(newValue) {
    console.log("Progress Modal State:", newValue);
  },
    async currentActiveNav(_val) {
      // this.filterUsers(_val);
      this.pektaType = "baru";

      this.filterOption.state = null;
      this.filterOption.gender = null;
      this.filterOption.status = null;
      this.filterOption.age.min = null;
      this.filterOption.age.max = null;
    },
    pektaType() {
      if (this.currentActiveNav == 3 || this.currentActiveNav == 2) {
        this.currentPage = 0;

        if (this.pektaType == "baru") this.getCurrentYearPekta(0);
        if (this.pektaType == "berdaftar") this.getPreviousYearsPekta(0);
      }

      if (this.currentActiveNav == 4) {
        this.currentPage = 0;
        this.getKatam(0);
      }

      if (this.currentActiveNav == 5) {
        this.currentPage = 0;
        this.getPihtas(0);
      }
    },
    filterOption: {
        handler() {
            if (this.currentActiveNav == 3){
                if (this.pektaType == "baru") this.getCurrentYearPekta(0);
                if(this.pektaType == "berdaftar") this.getPreviousYearsPekta(0);
            }
            if (this.currentActiveNav == 4){this.getKatam(0);}
            if (this.currentActiveNav == 5){this.getPihtas(0);}
        },
        deep: true,
    }
  },
  methods: {
    async filterUsers(_val) {
      switch (_val) {
        case 2:
          this.filteredUsers = [
            ...this.pektaUsers.filter((u) => {
              return (
                u.role &&
                u.role.id == 4 &&
                u.pekta &&
                u.pekta.pekta_applications.length > 0 &&
                u.pekta.pekta_applications[0].status == "approved"
              );
            }),
          ];
          console.log(this.filteredUsers);
          break;
        case 3:
          this.filteredUsers = [
            ...this.pektaUsers.filter((u) => {
              return (
                u.role &&
                u.role.id == 4 &&
                u.pekta &&
                u.pekta.pekta_applications.length > 0 &&
                u.pekta.pekta_applications[0].status != "approved"
              );
            }),
          ];
          console.log(this.filteredUsers);
          break;
        case 4:
          this.filteredUsers = [
            ...this.pektaUsers.filter((u) => {
              return (
                u.role &&
                u.role.id == 4 &&
                u.pekta &&
                u.pekta.katam_applications.length > 0 &&
                u.pekta.katam_applications[0].status != "approved"
              );
            }),
          ];
          console.log(this.filteredUsers);
          break;
        case 5:
          this.filteredUsers = [
            ...this.pektaUsers.filter((u) => {
              return (
                u.role &&
                u.role.id == 4 &&
                u.pekta &&
                u.pekta.pihtas_applications.length > 0 &&
                u.pekta.pihtas_applications[0].status != "approved"
              );
            }),
          ];
          console.log(this.filteredUsers);
          break;
        case 6:
          if (!this.picForm.id) {
            const res = await API.get("person-in-charges");
            Object.assign(this.picForm, res.data.data[0].attributes, {
              id: res.data.data[0].id,
            });
          }
          break;
      }
    },
    getBh(page) {
      this.currentPage = this.currentPage + page;
      return new Promise(async (resolve) => {
        this.isLoading = true;
        const query = qs.stringify(
          {
            filters: {
              role: {
                id: {
                  $eq: "5",
                },
              },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              "pekta.pekta_qualification.updatedAt:desc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.katam_applications.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
              offset: 100 * this.currentPage,
              limit: 100,
          },
          {
            encodeValuesOnly: true,
          }
        );
        const res = await API.get(`users?${query}`);
        this.users = res.data;
        this.isLoading = false;
        resolve();
      });
    },
    getAllBh() {
      return new Promise(async (resolve) => {
        this.isDownloading = true;
        let page = 1
        const pageSize = 500;

        const query = qs.stringify(
            {
                filters: {
                    role: {
                        id: {
                            $eq: "5",
                        },
                    },
                    // confirmed: {$eq: true},
                    blocked: {$eq: false},
                },
                populate: [
                    "role",
                ],
                offset: pageSize * page,
                limit: pageSize,
            },
            {
                encodeValuesOnly: true,
            }
        );

        // const res001 = await API.get(`users/count?${query}`);
        // console.log('count', res001.data);

        try {
            while(true) {
                console.log(`Fetching page ${page}...`);

                const res = await API.get(`users?${query}`);
                const data = res.data || [];
                this.allBh.push(...data);

                // Check if there are no more pages
                // if (data.length < pageSize) {
                if (page == 3) {
                    console.log('All data fetched.');
                    break;
                }

                page++;
            }
        } catch (error) {
            console.error('Error fetching data:', error.response?.data || error.message);
        }


        this.isDownloading = false;
        resolve();
      });
    },
    getApprovedPekta(page) {
      this.currentPage = this.currentPage + page;
      return new Promise(async (resolve) => {
        this.isLoading = true;
        const query = qs.stringify(
          {
            // limit: 20,
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                pekta_applications: {
                  status: {
                    $in: ["approved"],
                  },
                },
              },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              // 'updatedAt:desc',
              "pekta.pekta_qualification.updatedAt:desc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.katam_applications.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
            offset: 100 * this.currentPage,
            limit: 100,
          },
          {
            encodeValuesOnly: true,
          }
        );
        const res = await API.get(`users?${query}`);
        this.users = res.data;
        this.isLoading = false;
        resolve();
      });
    },
    getNotApprovedPekta(page) {
      this.currentPage = this.currentPage + page;
      return new Promise(async (resolve) => {
        this.isLoading = true;
        const query = qs.stringify(
          {
            // limit: 20,
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                pekta_applications: {
                  status: {
                    // $in: ['pending', 'approved', 'draft', 'processing', 'rejected']
                    $in: ["pending", "processing", "rejected", "interview"],
                  },
                },
              },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              // 'updatedAt:desc',
              "pekta.pekta_qualification.updatedAt:asc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.katam_applications.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
            offset: 100 * this.currentPage,
            limit: 100,
          },
          {
            encodeValuesOnly: true,
          }
        );
        const res = await API.get(`users?${query}`);
        this.users = res.data;
        this.isLoading = false;
        resolve();
      });
    },
    getCurrentYearPekta(page) {
      this.currentPage = this.currentPage + page;
      return new Promise(async (resolve) => {
        this.isLoading = true;
        const query = qs.stringify(
          {
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                pekta_applications: {
                  status: {
                    $in: [
                      "pending",
                      "approved",
                      "draft",
                      "processing",
                      "rejected",
                      "interview"
                    ],
                  },
                  createdAt: {
                    $gte: moment().startOf("year").format("YYYY-MM-DD"),
                    $lte: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  },
                },
              },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              "createdAt:desc",
              "pekta.pekta_qualification.updatedAt:asc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.katam_applications.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
            offset: 100 * this.currentPage,
            limit: 100,
          },
          {
            encodeValuesOnly: true,
          }
        );
        const res = await API.get(`users?${query}`);
        console.log(res.data)
        this.users = res.data;
        this.isLoading = false;
        // console.log(this.users)
        resolve();
      });
    },
    getPreviousYearsPekta(page) {
      this.currentPage = this.currentPage + page;
      return new Promise(async (resolve) => {
        this.isLoading = true;
        const queryOptions = {
            // limit: 20,
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                pekta_applications: {
                  status: {
                    $in: [
                      "pending",
                      "approved",
                      "draft",
                      "processing",
                      "rejected",
                    ],
                    // $in: ['pending', 'processing', 'rejected', 'interview']
                  },
                },
              },
              createdAt: {
                $lte: moment([moment().year() - 1])
                  .endOf("year")
                  .format("YYYY-MM-DD"),
              },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              // 'updatedAt:desc',
              "createdAt:desc",
              "pekta.pekta_qualification.updatedAt:asc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.katam_applications.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
            offset: 100 * this.currentPage,
            limit: 100,
        }

        if(this.filterOption.status){
            queryOptions.filters.pekta.pekta_applications.status.$in = [this.filterOption.status];
        }

        const query = qs.stringify(queryOptions, {encodeValuesOnly: true});

        const res = await API.get(`users?${query}`);
        this.users = res.data;
        this.isLoading = false;

        // console.log('users', this.users)

        resolve();
      })
    },
    getPektaTypeRangeDate() {
        let startDate = null;
        let endDate = null;
        if (this.pektaType == "baru") {
            (startDate = moment().startOf("year").format("YYYY-MM-DD")),
            (endDate = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
        } else if (this.pektaType == "berdaftar") {
            startDate = moment([2000]).startOf("year").format("YYYY-MM-DD");
            endDate = moment([moment().year() - 1])
            .endOf("year")
            .format("YYYY-MM-DD");
        }

        return { startDate, endDate };
    },
    getAllPekta() {
      return new Promise(async (resolve) => {
        this.isDownloading = true;
        let { startDate, endDate } = this.getPektaTypeRangeDate();

        const query = qs.stringify(
          {
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                pekta_applications: {
                  status: {
                    $in: [
                      "pending",
                      "approved",
                      "draft",
                      "processing",
                      "rejected",
                    ],
                    // $in: ['pending', 'processing', 'rejected', 'interview']
              },
              createdAt: {
                    $gte: startDate,
                    $lte: endDate,
                },
                },
              },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              // 'updatedAt:desc',
              "createdAt:desc",
              "pekta.pekta_qualification.updatedAt:asc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.katam_applications.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
          },
          {
            encodeValuesOnly: true,
          }
        );


        const res = await API.get(`users?${query}`);
        this.allPekta = res.data;

          //filter out the user data first (pekta_application)
         this.allPekta = res.data.map(user => ({
          ...user,
          pekta: {
            ...user.pekta,
            pekta_applications: user.pekta.pekta_applications.filter(app => {
              const appYear = moment(app.createdAt).format("YYYY-MM-DD")
              return appYear >= startDate && appYear <= endDate
            })
          }
        })),

        //filter out the assessment score (pekta, katam, pihtas).
        this.allPekta = res.data.map(user => ({
          ...user,
          pekta: {
            ...user.pekta,
            pekta_score: user.pekta.pekta_applications[0].pekta_application_assessments.length > 0
                        ? user.pekta.pekta_applications[0].pekta_application_assessments[0].pass_mark / user.pekta.pekta_applications[0].pekta_application_assessments[0].question_count * 100
                        : null
          }
        })),

        this.isDownloading = false;

        resolve()
      })
    },
    getKatam(page) {
      this.currentPage = this.currentPage + page;
      let { startDate, endDate } = this.getPektaTypeRangeDate();

      return new Promise(async (resolve) => {
        this.isLoading = true;
        const queryOptions = 
          {
            // limit: 20,
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                katam_applications: {
                  status: {
                    $in: ["pending", "approved", "processing", "rejected", "interview"],
                  },
                  createdAt: {
                    $gte: startDate,
                    $lte: endDate,
                  },
                },
              },
              // createdAt: {
              //   $gte: startDate,
              //   $lte: endDate,
              // },

            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.katam_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              'createdAt:desc',
              // "pekta.pekta_qualification.updatedAt:desc",
              // "pekta.pihtas_applications.updatedAt:desc",
              // "pekta.katam_applications.updatedAt:desc",
              // "pekta.katam_applications.pekta_application_assessments.updatedAt:desc",
              // "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              // "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
            offset: 100 * this.currentPage,
            limit: 100,
          } 
          if(this.filterOption.status){
            queryOptions.filters.pekta.katam_applications.status.$in = [this.filterOption.status];
        }

        const query = qs.stringify(queryOptions, {encodeValuesOnly: true});
        const res = await API.get(`users?${query}`);

        //filter out the user data first (katam_application)
        this.users = res.data.map(user => ({
          ...user,
          pekta: {
            ...user.pekta,
            katam_applications: user.pekta.katam_applications.filter(app => {
              const appYear = moment(app.createdAt).format("YYYY-MM-DD")
              if(!this.filterOption.status){
                return appYear >= startDate && appYear <= endDate
              } else{
                return appYear >= startDate && appYear <= endDate && app.status === this.filterOption.status;
              }
            })
          }
        }));

        this.isLoading = false;
        resolve();
      });
    },
    getAllKatam() {
      return new Promise(async (resolve) => {
        this.isDownloading = true;
        let { startDate, endDate } = this.getPektaTypeRangeDate();

        const query = qs.stringify(
          {
            // limit: 20,
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                katam_applications: {
                  status: {
                    $in: ["approved", "pending", "processing", "rejected", "interview"],
                  },
                  createdAt: {
                    $gte: startDate,
                    $lte: endDate,
                  },
                },
              },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              // 'updatedAt:desc',
              "pekta.pekta_qualification.updatedAt:desc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.katam_applications.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
          },
          {
            encodeValuesOnly: true,
          }
        );
        const res = await API.get(`users?${query}`);
        this.allKatam = res.data;

         //filter out the user data first (katam_application)
         this.allKatam = res.data.map(user => ({
          ...user,
          pekta: {
            ...user.pekta,
            katam_applications: user.pekta.katam_applications.filter(app => {
              const appYear = moment(app.createdAt).format("YYYY-MM-DD")
              return appYear >= startDate && appYear <= endDate
            })
          }
        }));

        //filter out the assessment score (pekta, katam, pihtas).
        this.allKatam = res.data.map(user => ({
          ...user,
          pekta: {
            ...user.pekta,
            katam_score: user.pekta.katam_applications.length > 0 && user.pekta.katam_applications[0].pekta_application_assessments && user.pekta.katam_applications[0].pekta_application_assessments.length > 0
                        ? user.pekta.katam_applications[0].pekta_application_assessments[0].pass_mark / user.pekta.katam_applications[0].pekta_application_assessments[0].question_count * 100
                        : null
          }
        })),

        this.isLoading = false;
        this.isDownloading = false;
        resolve();
      });
    },
    getPihtas(page) {
      this.currentPage = this.currentPage + page;

      let { startDate, endDate } = this.getPektaTypeRangeDate();

      return new Promise(async (resolve) => {
        this.isLoading = true;
        const queryOptions =
          {
            // limit: 20,
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                pihtas_applications: {
                  status: {
                    $in: [
                      "pending",
                      "draft",
                      "processing",
                      "rejected",
                      "interview",
                      "approved",
                    ],
                  },
                  createdAt: {
                    $gte: startDate,
                    $lte: endDate,
                  },
                },
              },
              // createdAt: {
              //   $gte: startDate,
              //   $lte: endDate,
              // },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pihtas_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              // 'updatedAt:desc',
              "pekta.pekta_qualification.updatedAt:desc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.pihtas_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
            offset: 100 * this.currentPage,
            limit: 100,
          }
          if(this.filterOption.status){
            queryOptions.filters.pekta.pihtas_applications.status.$in = [this.filterOption.status];
        }

        const query = qs.stringify(queryOptions, {encodeValuesOnly: true});
        const res = await API.get(`users?${query}`);
        this.users = res.data;

        //filter out the user data first (pihtas_application)
        this.users = res.data.map(user => ({
          ...user,
          pekta: {
            ...user.pekta,
            pihtas_applications: user.pekta.pihtas_applications.filter(app => {
              const appYear = moment(app.createdAt).format("YYYY-MM-DD")
              if(!this.filterOption.status){
                return appYear >= startDate && appYear <= endDate
              } else{
                return appYear >= startDate && appYear <= endDate && app.status === this.filterOption.status;
              }
            })
          }
        }));

        this.isLoading = false;
        resolve();
      });
    },
    getAllPihtas() {
      return new Promise(async (resolve) => {
        this.isDownloading = true;
        let { startDate, endDate } = this.getPektaTypeRangeDate();

        const query = qs.stringify(
          {
            // limit: 20,
            filters: {
              role: {
                id: {
                  $eq: "4",
                },
              },
              pekta: {
                pihtas_applications: {
                  status: {
                    $in: [
                      "pending",
                      "draft",
                      "processing",
                      "rejected",
                      "interview",
                      "approved",
                    ],
                  },
                  createdAt: {
                    $gte: startDate,
                    $lte: endDate,
                  },
                },
              },
            },
            populate: [
              "role",
              "pekta",
              "pekta.pekta_applications",
              "pekta.pekta_applications.pekta_application_assessments",
              "pekta.pekta_applications.pekta_qualification",
              "pekta.pekta_applications.pekta_qualification.cert_file",
              "pekta.katam_applications",
              "pekta.katam_applications.file_kutahu_cert",
              "pekta.katam_applications.file_mhpk_cert",
              "pekta.katam_applications.file_tafaqquh_haji_cert",
              "pekta.katam_applications.file_tauliah_cert",
              "pekta.pihtas_applications",
              "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
              "pekta.pihtas_applications.file_izin_suami_wali",
            ],
            sort: [
              // 'updatedAt:desc',
              "pekta.pekta_qualification.updatedAt:desc",
              "pekta.pihtas_applications.updatedAt:desc",
              "pekta.katam_applications.updatedAt:desc",
              "pekta.pekta_applications.pekta_application_assessments.updatedAt:desc",
              "pekta.pekta_applications.pekta_qualification.end_date:desc",
            ],
          },
          {
            encodeValuesOnly: true,
          }
        );
        const res = await API.get(`users?${query}`);
        this.allPihtas = res.data;

          //filter out the user data first (pihtas_application)
        this.allPihtas = res.data.map(user => ({
          ...user,
          pekta: {
            ...user.pekta,
            pihtas_applications: user.pekta.pihtas_applications.filter(app => {
              const appYear = moment(app.createdAt).format("YYYY-MM-DD")
              return appYear >= startDate && appYear <= endDate
            })
          }
        }));

        //filter out the assessment score (pekta, katam, pihtas).
        this.allPihtas = res.data.map(user => ({
          ...user,
          pekta: {
            ...user.pekta,
            pihtas_score: user.pekta.pihtas_applications.length > 0 && user.pekta.pihtas_applications[0].pekta_application_assessments && user.pekta.pihtas_applications[0].pekta_application_assessments.length > 0
                        ? user.pekta.pihtas_applications[0].pekta_application_assessments[0].pass_mark / user.pekta.pihtas_applications[0].pekta_application_assessments[0].question_count * 100
                        : null
          }
        })),

        this.isDownloading = false;
        resolve();
      });
    },
    async getPic() {
      if (!this.picForm.id) {
        const res = await API.get("person-in-charges");
        Object.assign(this.picForm, res.data.data[0].attributes, {
          id: res.data.data[0].id,
        });
      }
    },
    getYearOnly(_dateStr){
      return moment(_dateStr).year()
    },
    getPektaUser(page) {
      switch (this.currentActiveNav) {
        case 2:
          this.getApprovedPekta(page);
          break;
        case 3:
          if (this.pektaType == 'baru'){
            this.getCurrentYearPekta(page);
          } else {
            this.getPreviousYearsPekta(page);
          }
          break;
        case 4:
          this.getKatam(page);
          break;
        case 5:
          this.getPihtas(page);
          break;
      }
    },
    getStatus(user) {
      switch (this.currentActiveNav) {
        case 3:
          const pektaStatus = this.pektaStatusList.filter((o) => {
            //take the last data of user.pekta.pekta_applications
            return o.value == user.pekta.pekta_applications[user.pekta.pekta_applications.length - 1].status;
          });
          return pektaStatus[0].name;
          break;
        case 4:
          const latestKatamApplication = user.pekta.katam_applications
            .slice()
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

          const katamStatus = this.pektaStatusList.filter((o) => {
            return o.value == latestKatamApplication.status;
          });
          return katamStatus[0].name;
          break;
        case 5:
          const latestPihtasApplication = user.pekta.pihtas_applications
            .slice()
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

          const pihtasStatus = this.pektaStatusList.filter((o) => {
            return o.value == latestPihtasApplication.status;
          });
          return pihtasStatus[0].name;
          break;
      }
    },
    async openChangeModal(user, type) {
      switch (type) {
        case "pekta":
          //temporary
          let userIC, userPhone;

          const latestPektaApplication = user.pekta.pekta_applications
            .slice()
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

          if (latestPektaApplication.nric) {
            userIC = latestPektaApplication.nric;
          } else if (user.id_no) {
            userIC = user.id_no;
          } else {
            userIC = "";
          }

          if (latestPektaApplication.phone_number) {
            userPhone = latestPektaApplication.phone_number;
          } else if (user.id_no) {
            userPhone = user.phone_mobile;
            if(userPhone.startsWith('00+')){//if userPhone contains how much zero right before '+6' at the beginning, remove those zeros. And also, if userPhone doest not contain '+6', then add '+6'
              userPhone = userPhone.substring(2)
              if(!userPhone.includes('+6')){
                userPhone = '+6' + userPhone
              }
            }
          } else {
            userPhone = "";
          }

          this.pektaApplication = {
            userId: user.id,
            pektaId: user.pekta.id,
            applicationId: latestPektaApplication.id,
            registration_number: user.registration_number,
            originalStatus: latestPektaApplication.status,
            status: latestPektaApplication.status,
            fullname: user.full_name,
            employer_name: latestPektaApplication.employer_name,
            nric: userIC,
            submitted_date:
              latestPektaApplication.submitted_date !== null
                ? moment(
                  latestPektaApplication.submitted_date
                  ).format("YYYY-MM-DD")
                : "Tiada Data",
            marital_status: latestPektaApplication.marital_status,
            phone_number: userPhone,
            do_smoking: latestPektaApplication.do_smoking,
            occupation: latestPektaApplication.occupation,
            qualification_start_date: null,
            qualification_end_date: null,
            pekta_qualification:
              latestPektaApplication.pekta_qualification,
            pekta_application_assessments:
              latestPektaApplication.pekta_application_assessments
                .length > 0
                ? latestPektaApplication
                    .pekta_application_assessments[0]
                : null,
          };

          if(latestPektaApplication.pekta_qualification.length > 0){
            const foundQualification = latestPektaApplication.pekta_qualification.find(item => item.type == 'Sijil Tauliah Mengajar Agama Negeri')
            if(foundQualification){
              this.pektaApplication.qualification_start_date = foundQualification.start_date
              this.pektaApplication.qualification_end_date = foundQualification.end_date
            }
          }
          break;
        case "katam":
          const latestKatamApplication = user.pekta.katam_applications
            .slice()
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

          this.katamApplication = {
            userId: user.id,
            pektaId: user.pekta.id,
            applicationId: latestKatamApplication.id,
            originalStatus: latestKatamApplication.status,
            status: latestKatamApplication.status,
            file_kutahu_cert: latestKatamApplication.file_kutahu_cert,
            file_mhpk_cert: latestKatamApplication.file_mhpk_cert,
            file_tafaqquh_haji_cert:
              latestKatamApplication.file_tafaqquh_haji_cert,
            file_tauliah_cert: latestKatamApplication.file_tauliah_cert,
            qualification_start_date: latestKatamApplication.qualification_start_date,
            qualification_end_date: latestKatamApplication.qualification_end_date,
            pekta_application_assessments:
              latestKatamApplication.pekta_application_assessments
                .length > 0
                ? latestKatamApplication
                    .pekta_application_assessments[0]
                : null,
          };
          break;
        case "pihtas":
          const latestPihtasApplication = user.pekta.pihtas_applications
            .slice()
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

          this.pihtasApplication = {
            userId: user.id,
            pektaId: user.pekta.id,
            applicationId: latestPihtasApplication.id,
            originalStatus: latestPihtasApplication.status,
            status: latestPihtasApplication.status,
            file_sijil_pembimbing_ibadat:
              latestPihtasApplication.file_sijil_pembimbing_ibadat,
            file_izin_suami_wali:
              latestPihtasApplication.file_izin_suami_wali,
            sijil_pembimbing_ibadat_year:
              latestPihtasApplication.sijil_pembimbing_ibadat_year,
            pekta_application_assessments:
              latestPihtasApplication.pekta_application_assessments
                .length > 0
                ? latestPihtasApplication
                    .pekta_application_assessments[0]
                : null,
          }
          break;
      }
    },
    async updatePektaApplication() {
      console.log(this.pektaApplication.registration_number);
      if (this.pektaApplication.registration_number)
        await API.put(`users/${this.pektaApplication.userId}`, {
          registration_number: this.pektaApplication.registration_number,
        });

      console.log(
        "this.pektaApplication.status : ",
        this.pektaApplication.status
      );
      if (this.pektaApplication.originalStatus !== this.pektaApplication.status)
        await API.put(
          `pekta-applications/${this.pektaApplication.applicationId}?populate[pekta][populate][0]=user`,
          { data: { status: this.pektaApplication.status } }
        );

      // No need to add 1 year expiry date.
      // if (this.pektaApplication.status == "approved")
      //   await API.put(`pektas/${this.pektaApplication.pektaId}`, {
      //     data: {
      //       expiry_date: new Date(
      //         new Date().setFullYear(new Date().getFullYear() + 1)
      //       ).toISOString(),
      //     },
      //   });

      $("#pektaStatusChange").toggle();
      $(".modal-backdrop").remove();
      await this.getPektaUser(0);
    },
    async updateKatamApplication() {
      if (this.katamApplication.originalStatus !== this.katamApplication.status)
        await API.put(
          `katam-applications/${this.katamApplication.applicationId}`,
          { data: { status: this.katamApplication.status } }
        );

      if (this.katamApplication.status == "approved"){
        const resPektaSet = await API.get('pekta-setting')
        const expiryDate = resPektaSet.data.data.attributes.katam_expiry_date

        if(expiryDate){
          await API.put(`pektas/${this.katamApplication.pektaId}`, {
            data: {
              expiry_date: expiryDate,
            },
          });
          // await API.put(`pektas/${this.katamApplication.pektaId}`, {
          //   data: {
          //     expiry_date: new Date(
          //       new Date().setFullYear(new Date().getFullYear() + 1)
          //     ).toISOString(),
          //   },
          // });
        }
      }

      $("#katamStatusChange").toggle();
      $(".modal-backdrop").remove();

      await this.getPektaUser(0);
    },
    async updatePihtasApplication() {
      if (
        this.pihtasApplication.originalStatus !== this.pihtasApplication.status
      )
        await API.put(
          `pihtas-applications/${this.pihtasApplication.applicationId}`,
          { data: { status: this.pihtasApplication.status } }
        );

      if (this.pihtasApplication.status == "approved")
        await API.put(`pektas/${this.pihtasApplication.pektaId}`, {
          data: { is_pihtas: true },
        });

      $("#pihtasStatusChange").toggle();
      $(".modal-backdrop").remove();

      await this.getPektaUser(0);
    },
    async updatePersonInCharge() {
      await API.put(`person-in-charges/${this.picForm.id}`, {
        data: this.picForm,
      });
    },
    getYear(date) {
      return moment(date).format("YYYY");
    },
    getDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    generateYears(startYear, endYear) {
      const years = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }
      return years;
    },
    findNameUsers() {
      let finalUsers = this.users;

    //   if (this.currentActiveNav == 3){
    //     if (this.filterOption.year) {
    //       finalUsers = finalUsers.filter((x) => {
    //         if (x.registration_number != null)
    //           return x.registration_number
    //             .substring(0, 4)
    //             .includes(this.filterOption.year);
    //       });
    //     } else if (this.filterOption.status) {
    //       finalUsers = finalUsers.filter((x) => {
    //         return x.pekta.pekta_applications[0].status.includes(this.filterOption.status);
    //       });
    //     }
    //   }

    //   if (this.currentActiveNav == 4){
    //     if (this.filterOption.year) {
    //       finalUsers = finalUsers.filter((x) => {
    //         if (x.registration_number != null)
    //           return x.registration_number
    //             .substring(0, 4)
    //             .includes(this.filterOption.year);
    //       });
    //     } else if (this.filterOption.status) {
    //       finalUsers = finalUsers.filter((x) => {
    //         const latestKatamApplication = x.pekta.katam_applications
    //           .slice()
    //           .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
    //         return latestKatamApplication.status.includes(this.filterOption.status);
    //       });

    //     }
    //   }

    //   if (this.currentActiveNav == 5){
    //     if (this.filterOption.year) {
    //       finalUsers = finalUsers.filter((x) => {
    //         if (x.registration_number != null)
    //           return x.registration_number
    //             .substring(0, 4)
    //             .includes(this.filterOption.year);
    //       });
    //     } else if (this.filterOption.status) {
    //       finalUsers = finalUsers.filter((x) => {
    //         return x.pekta.pihtas_applications[0].status.includes(this.filterOption.status);
    //       });

    //     }
    //   }

    //   if (this.filterOption.state) {
    //     finalUsers = finalUsers.filter((x) => {
    //       if (x.state != null)
    //         return x.state
    //           .toLowerCase()
    //           .includes(this.filterOption.state.toLowerCase());
    //     });
    //   }
      return finalUsers;
    },
    isImage(fileLink) {
      if (fileLink) {
        // Determine if the file is an image based on its extension
        const fileExtension = fileLink.split(".").pop().toLowerCase();
        return ["jpg", "jpeg", "png", "gif"].includes(fileExtension);
      }
      return false;
    },
    getPektaStartTauliahDate(user){
      const pektaApplication = user.pekta.pekta_applications
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

      if (pektaApplication.pekta_qualification.length > 0){
        const foundQualification = pektaApplication.pekta_qualification.find(item => item.type == 'Sijil Tauliah Mengajar Agama Negeri')
        if(foundQualification){
          return foundQualification.start_date
        }
      }
      return null
    },
    getPektaEndTauliahDate(user){
      const pektaApplication = user.pekta.pekta_applications
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

      if (pektaApplication.pekta_qualification.length > 0){
        const foundQualification = pektaApplication.pekta_qualification.find(item => item.type == 'Sijil Tauliah Mengajar Agama Negeri')
        if(foundQualification){
          return foundQualification.end_date
        }
      }
      return null
    },
    openProgressModal() {
    this.showProgressModal = true; 
    this.progress = 0;
    this.downloadedRecords = 0;
    this.totalRecords = 0; 
  },
  closeProgressModal() {
    this.showProgressModal = false; 
  },

    async handleDownload(_type) {
  this.isDownloading = true; 
  this.openProgressModal(); 
  this.progress = 0; 
  this.downloadedRecords = 0; 
  this.totalRecords = 0;

  const limit = 100; 
  let currentPage = 0; 
  const allData = []; 

  const setProgress = () => {
    if (this.totalRecords > 0) {
      this.progress = Math.min(100, Math.floor((this.downloadedRecords / this.totalRecords) * 100));
    }
  };

  let queryOptions; 
  let exportFileName; 
  let exportHeaders; 

  try {
    if (_type === 'permohonan_pekta') {
      let { startDate, endDate } = this.getPektaTypeRangeDate();

      queryOptions = {
        filters: {
          role: { id: { $eq: "4" } },
          pekta: {
            pekta_applications: {
              status: { $in: ["pending", "approved", "draft", "processing", "rejected"] },
              createdAt: { $gte: startDate, $lte: endDate },
            },
          },
        },
        populate: [
          "role",
          "pekta",
          "pekta.pekta_applications",
          "pekta.pekta_applications.pekta_application_assessments",
          "pekta.pekta_applications.pekta_qualification",
          "pekta.pekta_applications.pekta_qualification.cert_file",
          "pekta.katam_applications",
          "pekta.pihtas_applications",
        ],
        sort: ["createdAt:desc"],
      };

      exportFileName = "permohonan_pekta.xlsx";
      exportHeaders = [
        "#",
        "No. Pendaftaran PEKTA",
        "Nama",
        "No Kad Pengenalan",
        "Umur",
        "Jantina",
        "Emel",
        "Negeri",
        "Daerah",
        "No. Telefon",
        "Status",
        "Markah Ujian",
        "Tarikh Dihantar",
        "Tarikh Mula Tauliah Negeri",
        "Tarikh Tamat Tauliah Negeri",
        "Nama Majikan",
        "Jenis Akademik 1",
        "Tahap Akademik 1",
        "Tahun Akademik 1",
        "Sijil Akademik 1",
        "Jenis Akademik 2",
        "Tahap Akademik 2",
        "Tahun Akademik 2",
        "Sijil Akademik 2",
        "Jenis Akademik 3",
        "Tahap Akademik 3",
        "Tahun Akademik 3",
        "Sijil Akademik 3",
        "Status Perkahwinan",
      ];
    } else if (_type === 'permohonan_katam') {
      let { startDate, endDate } = this.getPektaTypeRangeDate();

      queryOptions = {
        filters: {
          role: { id: { $eq: "4" } },
          pekta: {
            katam_applications: {
              status: { $in: ["approved", "pending", "processing", "rejected", "interview"] },
              createdAt: { $gte: startDate, $lte: endDate },
            },
          },
        },
        populate: [
          "role",
          "pekta",
          "pekta.katam_applications",
          "pekta.katam_applications.file_kutahu_cert",
          "pekta.katam_applications.file_mhpk_cert",
          "pekta.katam_applications.file_tafaqquh_haji_cert",
          "pekta.katam_applications.file_tauliah_cert",
        ],
        sort: ["pekta.katam_applications.updatedAt:desc"],
      };

      exportFileName = "permohonan_katam.xlsx";
      exportHeaders = [
        "#",
        "Nama",
        "No. Pendaftaran PEKTA",
        "IC",
        "Emel",
        "Negeri",
        "Daerah",
        "No. Telefon",
        "Status Permohonan",
        "Markah Ujian",
        "Tarikh Dihantar",
      ];
    } else if (_type === 'permohonan_pihtas') {
      let { startDate, endDate } = this.getPektaTypeRangeDate();

      queryOptions = {
        filters: {
          role: { id: { $eq: "4" } },
          pekta: {
            pihtas_applications: {
              status: { $in: ["pending", "draft", "processing", "rejected", "interview", "approved"] },
              createdAt: { $gte: startDate, $lte: endDate },
            },
          },
        },
        populate: [
          "role",
          "pekta",
          "pekta.pihtas_applications",
          "pekta.pihtas_applications.file_sijil_pembimbing_ibadat",
          "pekta.pihtas_applications.file_izin_suami_wali",
        ],
        sort: ["pekta.pihtas_applications.updatedAt:desc"],
      };

      exportFileName = "permohonan_pihtas.xlsx";
      exportHeaders = [
        "#",
        "Nama",
        "No. Pendaftaran PEKTA",
        "IC",
        "Emel",
        "Negeri",
        "Daerah",
        "No. Telefon",
        "Status Permohonan",
        "Markah Ujian",
        "Tarikh Dihantar",
      ];
    } else if (_type === 'senarai_bh') {
      queryOptions = {
        filters: {
          role: { id: { $eq: "5" } },
          blocked: { $eq: false },
        },
        populate: ["role"],
        sort: ["createdAt:desc"],
      };

      exportFileName = "senarai_bh.xlsx";
      exportHeaders = [
        "#",
        "Nama",
        "No. Bakal Haji",
        "IC",
        "Emel",
        "Negeri",
      ];
    }

    const countQuery = qs.stringify(queryOptions.filters, {
      encodeValuesOnly: true,
    });
    const totalResponse = await API.get(`users/count?${countQuery}`);
    this.totalRecords = totalResponse.data;

    console.log(`Total records to fetch: ${this.totalRecords}`);

    // Fetch Paginated Data
    while (true) {
      queryOptions.offset = limit * currentPage;
      queryOptions.limit = limit;
      const query = qs.stringify(queryOptions, { encodeValuesOnly: true });

      const res = await API.get(`users?${query}`);
      if (!res.data || res.data.length === 0) {
        console.log("No more data to fetch.");
        break;
      }

      allData.push(...res.data);
      this.downloadedRecords += res.data.length;
      currentPage++;
      setProgress();

      console.log(
        `Fetched ${res.data.length} records this batch. Total downloaded: ${this.downloadedRecords}/${this.totalRecords}`
      );
    }

    // Map Data for Excel
    const data4Excel = allData.map((user, index) => {
      switch (_type) {
        case "permohonan_pekta":
          const qualifications =
            user.pekta?.pekta_applications?.[0]?.pekta_qualification || [];
          const certs = qualifications.slice(0, 3).map((cert) => ({
            type: cert.type || "Tiada",
            level: cert.level || "Tiada",
            year: moment(cert.end_date).isValid()
              ? moment(cert.end_date).format("YYYY")
              : "Tiada",
            url: cert.cert_file?.url || "Tiada",
          }));
          const finalCerts = [
            ...certs,
            ...Array(3 - certs.length).fill({
              type: "Tiada",
              level: "Tiada",
              year: "Tiada",
              url: "Tiada",
            }),
          ];
          return [
            index + 1,
            user.registration_number || "-",
            user.full_name || "-",
            user.id_no || "-",
            user.age || "-",
            user.gender || "-",
            user.email || "-",
            user.state?.toUpperCase() || "-",
            user.district?.toUpperCase() || "-",
            user.phone_mobile || "-",
            this.getStatus(user) || "-",
            user.pekta?.pekta_score?.toFixed(2) + "%" || "-",
            moment(user.pekta?.pekta_applications?.[0]?.createdAt).format(
              "DD-MM-YYYY"
            ) || "-",
            this.getPektaStartTauliahDate(user) || "-",
            this.getPektaEndTauliahDate(user) || "-",
            user.pekta?.pekta_applications?.[0]?.employer_name || "-",
            ...finalCerts.flatMap((cert) => [
              cert.type,
              cert.level,
              cert.year,
              cert.url,
            ]),
            user.pekta?.pekta_applications?.[0]?.marital_status === "single"
              ? "Bujang"
              : "Berkahwin",
          ];
        case "permohonan_katam":
        case "permohonan_pihtas":
          // Similar mapping logic for katam and pihtas
        case "senarai_bh":
          return [
            index + 1,
            user.full_name || "-",
            user.registration_number || "-",
            user.id_no || "-",
            user.email || "-",
            user.state?.toUpperCase() || "-",
          ];
      }
    });

    // Export to Excel
    this.exportToExcel(data4Excel, exportFileName, exportHeaders);
  } catch (error) {
    console.error("Error during download:", error);
  } finally {
    this.isDownloading = false;
    this.progress = 100;
    this.closeProgressModal();
  }
},

exportToExcel(data, fileName, headers) {
  if (!data || data.length === 0) {
    console.warn("No data to export.");
    return;
  }

  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, fileName);
  console.log(`Excel file "${fileName}" exported successfully.`);
},
    addHttps(url) {
      if (!url.startsWith('https://') && !url.startsWith('http://')) {
        return 'https://' + url;
      }
      return url;
    },
    init(){
      return new Promise(async (resolve, reject) => {
        const res = await API.get('pekta-setting')
        this.pektaSetting = res.data.data.attributes

        resolve(0)
      })
    },
  },
  async mounted() {
    this.currentActiveNav = 1;
    await this.init();
    await this.getBh(0);
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center; /* Centers modal inside overlay */
  z-index: 9999;
}

.modal-download {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  width: 600px; /* Set modal width */
  max-width: 90%; /* Prevents overflow on small screens */
  text-align: center;
  position: relative; /* Ensures modal is positioned inside overlay */
  display: flex;
  flex-direction: column;
  height: 30%;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 0;
}

.progress-bar-fill {
  height: 100%;
  background: #007bff;
  transition: width 0.4s ease;
}

.pill-content {
  padding: 0 12px !important;
}

.custom-list {
  border: 0;
  border-bottom: 1px solid #b5b5b5;

  .inbox-date {
    font-size: 13px;
    color: #6e6e6e;
  }
}

.upload-csv {
  border: 1px solid #f8f8f8;
  border-radius: 8px;
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
  width: 100%;
  span {
    margin-top: 0.8rem;
  }
}

.contents {
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: $gray-reqular !important;
        cursor: pointer;

        &.active {
          border: unset;
          border-bottom: 1px solid $green;
          color: $green !important;
        }
      }
    }
  }
}

.btn-coupled {
  .btn {
    color: rgb(82, 145, 44);

    &.btn-left {
      border: 1px solid rgb(82, 145, 44);
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
    }

    &.btn-right {
      border: 1px solid rgb(82, 145, 44);
      border-top-left-radius: unset !important;
      border-bottom-left-radius: unset !important;
    }

    &.active {
      color: #fff;
      background-color: rgb(82, 145, 44);
    }
  }
}
</style>
